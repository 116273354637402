/* @import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500;600;700;800&family=Montserrat:wght@100;300;400;500;600;700;800;900&display=swap%27'); */

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* background-color: #370356; */
  /* background-color: #1B2430 */
  margin: 0;
  /* font-family: "Philosopher", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif; */

  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
  font-family: "Raleway", sans-serif;
}

#inner {
  transform: perspective(1500px) rotateY(0deg);
  transition: transform 1s ease 0s;
}

#inner:hover {
  transform: perspective(3000px) rotateY(15deg);
  transform: perspective(1500px) rotateY(-15deg);
}

.blurCustom {
  backdrop-filter: blur(20px);
}
